@import '../../css//variables';

@keyframes MOVE-BG {
  from {
    top: 0;
  }
  to {
    top: -312px;
  }
}

#footer {
  position: relative;
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  overflow: hidden;

  &__background {
    width: 100%;
    height: 700%;
    color: $white;
    position: absolute;
    top: 0;
    left: 0;
    animation-name: MOVE-BG;
    animation-duration: 30s;
    animation-timing-function: linear;
  }

  &__background:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $primaryWithOpacity;
  }

  &__container {
    position: absolute;
    top: 0;
    color: $white;
    display: block;
    height: 100%;
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    @media (max-width: 500px) {
      padding: 0;
    }
  }

  &__copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: lighter;
    font-size: 0.8rem;
    color: $white;

    @media (max-width: 750px) {
      width: 100%;
    }
  }

  &__nav {
    width: 50%;
    height: 100%;

    @media (max-width: 1200px) {
      width: 80%;
      font-size: 14px;

    }

    @media (max-width: 750px) {
      display: none;
    }

    & ul,
    & li {
      display: flex;
      width: 100%;
      height: 100%;
    }

    & a {
      list-style-type: none;
      margin: 0 auto;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: $whiteWithopacity;
    }

    & a:hover {
      color: $white;
      text-shadow: 0px 0px 10px $text-shadow;
    }
  }
}

