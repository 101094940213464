* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    font-size: 100%;
}

html, body, #root {
    width: 100%;
    height: 100%;
}

html {
    scroll-padding-top: 55px;
}

body {
    font-family: Verdana, Tahoma, sans-serif;
  }