@import '../../../css/variables';

.slides {
  display: grid;
  > .slide {
    grid-area: 1 / -1;
  }

  width: 100%;
  perspective: 800px;
  transform-style: preserve-3d;

  img {
    width: 100%;
    height: auto;
    border: 1px solid $lightGrey1;
    border-radius: 5px;
  }
}

.slide {
  width: 50%;
  margin: 0 auto;
  opacity: calc(1 - (var(--offset) * var(--dir)) / 3);
  display: flex;
  align-items: center;

  cursor: pointer;

  transform: translateX(calc(55% * var(--offset) - 10% * (var(--offset))))
    translateY(calc(15% * var(--offset) * var(--dir)))
    rotateY(calc(-30deg * var(--offset)))
    scaleX(calc(1 - 0.4 * var(--offset) * var(--dir)))
    scaleY(calc(1 - 0.4 * var(--offset) * var(--dir)));

  transition: transform 1s ease-in-out, opacity 1.2s ease-in-out;
}

.slide[data-active] {
  transform: translateZ(20px);
}
