@import '../../css//variables';

#mobile-nav {
  color: $black;
  background-color: $white;
  width: 50%;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: $black;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
  }

  li {
    list-style-type: none;
  }
}
