@import '../../css/variables';

#contact{
  width: 100%;
  background-color: $white;
  border-top: 1px solid rgba($primaryWithoutOpacity, 0.5);
  border-bottom: 1px solid rgba($primaryWithoutOpacity, 0.5);

  &__container {
    width: 100%;
    max-width: 1200px;
    color: $black;
    height: 100%;
    margin: 0 auto 100px;
    display: flex;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

  }

  &__header {
    width: 100%;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;

    h2 {
      z-index: 100;
      font-weight: normal;
      letter-spacing: 10px;
      color: $primaryWithoutOpacity;
      text-transform: uppercase;
      font-size: 30px;
      position: relative;
      top: 20px;

      @media (max-width: 800px) {
        font-size: 24px;
      }

      @media (max-width: 600px) {
        font-size: 20px;
        letter-spacing: 5px;
      }
    }

    h2:after {
      content: "";
      display: flex;
      width: 200%;
      height: 1px;
      margin: 10px auto 0;
      position: absolute;
      left: -50%;

      @media (max-width: 450px) {
        width: 150%;
        left: -25%;
      }

      background-image: linear-gradient(
      to right,
      $lightGrey1,
      $primaryWithOpacity 40%,
      $primaryWithOpacity 60%,
      $lightGrey1
    );
    }
  }

}

form {
  margin-top: 100px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  color: $primaryWithoutOpacity;
  border-radius: 5px;
  padding: 20px 10vw;
}

.input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.label-main {
  margin-bottom: 0.5rem;
}

.input-field {
  height: 2rem;
  padding-left: 1rem;
  font-size: 1rem;
  border-radius: 5px;
  border: none;
  background-color: $white;
  border: 1px solid $primaryWithoutOpacity;
}

#contact__form * {
  outline: none;
}

#contact__form *:focus,
#contact__form button:hover {
  box-shadow: 0 0 6px 3px rgba($primaryWithoutOpacity, 0.25);
}

#message {
  border-radius: 5px;
  border: 1px solid $primaryWithoutOpacity;
  padding: 1rem;
  font-family: sans-serif;
  font-size: 1rem;
  background-color: white;
}

#submit {
  height: 2rem;
  padding-left: 1rem;
  font-size: 1rem;
  border-radius: 5px;
  margin-top: 1rem;
  border: none;
  text-transform: uppercase;
  background-color: $white;
  border: 1px solid $primaryWithoutOpacity;
  color: $primaryWithoutOpacity;
  cursor: pointer;
}

#submit:hover {
  color: $white;
  background-color: $primaryWithoutOpacity;
}

#submit:disabled {
  border: 1px solid $notValid;
  color: $notValid;
  cursor: auto;
  box-shadow: none;
  pointer-events: none;
}

.label-not-valid {
    color: $notValid;
}

.not-valid {
  box-shadow: 0 0 6px 3px $notValid;
}