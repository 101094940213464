@import '../../css//variables';

#welcome {
  width: 100%;
  height: 655px;
  background-color: $white;
  display: flex;
  align-items: center;
  padding: 55px 20px 0;

  @media (max-width: 850px) {
    height: auto;
    padding: 55px 0 0;
  }

  &__container {
    width: 100%;
    max-width: 1200px;
    color: $white;
    height: 50%;
    margin: 0 auto;
    display: flex;
    border-top-left-radius: 500px;
    border-bottom-left-radius: 500px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    position: relative;
    overflow: hidden;
    padding: 20px;

    @media (max-width: 850px) {
      border-radius: 0;
      flex-direction: column;
      align-items: center;
      padding: 0;
    }
  }

  &__background {
    width: 100%;
    height: 700%;
    color: $white;
    position: absolute;
    top: 0;
    left: 0;
    animation-name: MOVE-BG;
    animation-duration: 30s;
    animation-timing-function: linear;
    background: transparent;

    @media (max-width: 850px) {
      display: none;
      background: none;
    }
  }

  &__background:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $primaryWithOpacity;

    @media (max-width: 850px) {
      display: none;
    }
  }

  &__image-wrapper {
    z-index: 1;
    position: relative;
    display: flex;

    @media (max-width: 850px) {
      width: 38vw;
      height: 38vw;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px;
      background: url(../../assets/bg_code.jpg) repeat;
      border-radius: 500px;
      overflow: hidden;
    }

    img {
      height: 100%;
      border-radius: 50%;
      z-index: 1;
      position: absolute;
      box-shadow: 0px 0px 2px 2px $white;

      @media (max-width: 850px) {
        width: 90%;
        height: auto;
      }
    }
  }

  &__image-wrapper:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $primaryWithoutOpacity;
  }

  &__text-area-wrapper {
    z-index: 1;
    border-radius: 5px;
    color: $white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    font-size: 25px;
    text-transform: uppercase;
    position: relative;

    p {
      margin-bottom: 15px;
    }

    p.flex {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 500px) {
        flex-direction: column;
        justify-content: space-between;
      }
    }

    .text-big {
      font-size: 40px;

      @media (max-width: 1200px) {
        font-size: 3vw;
      }

      @media (max-width: 700px) {
        font-size: 3.5vw;
      }

      @media (max-width: 500px) {
        font-size: 20px;
        text-align: center;
      }
    }

    .text-with-tab {
      text-indent: 50px;
      font-style: italic;

      @media (max-width: 700px) {
        text-indent: 3vw;
      }

      @media (max-width: 500px) {
        text-indent: 0;
      }
    }

    margin-left: 336px;
    margin-right: 40px;

    @media (max-width: 1200px) {
      font-size: 2vw;
      margin-left: 296px;
      margin-right: 0px;
      padding: 0;
    }

    @media (max-width: 700px) {
      font-size: 2.5vw;
    }

    @media (max-width: 500px) {
      font-size: 14px;
      text-align: center;
    }

    @media (max-width: 850px) {
      margin: 0;
      margin-bottom: 20px;
    }

    a:visited,
    a:active,
    a:link,
    a:focus {
      outline: none;
      text-decoration: none;
    }

    mark {
      background-color: transparent;
      color: black;
    }

    mark:hover,
    mark:focus {
      background-color: $marked;
      outline: none;
    }
  }

  &__action {
    color: $white;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid $white;

    @media (max-width: 850px) {
      color: $primaryWithoutOpacity;
      border: 1px solid $primaryWithoutOpacity;
    }

    @media (max-width: 500px) {
      margin-top: 20px;
    }
  }

  &__action:hover {
    color: $primaryWithoutOpacity;
    background-color: $white;

    @media (max-width: 850px) {
      color: $white;
      background-color: $primaryWithoutOpacity;
    }
  }

  &__text-area {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;

    @media (max-width: 1200px) {
      height: 90%;
    }

    @media (max-width: 850px) {
      margin: 0.5vw 10vw;
      width: 100%;
      border-radius: 5px;
      color: $primaryWithoutOpacity;
      padding: 3vw 10vw 2vw;
      border: 1px solid $primaryWithoutOpacity;
      justify-content: space-between;
    }

    @media (max-width: 700px) {
      padding: 3vw 5vw 2vw;
    }

    @media (max-width: 500px) {
      align-items: center;
    }

    @media (max-width: 400px) {
      padding: 20px;
    }
  }
}
