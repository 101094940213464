@import '../../../css/variables';

$marginTop: 40px;

.project {
  display: flex;
  width: 300px;
  height: 400px;
  background-color: white;
  border: 1px solid $primaryWithOpacity;
  margin: 50px;
  border-radius: 5px;
  position: relative;

  @media (max-width: 800px) {
    margin: 20px 50px;
  }

  @media (max-width: 400px) {
    margin: 0;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $primaryWithOpacity;
    height: 420px;
  }

  &__header {
    width: 70%;
    height: 30px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 15px;
    left: 0px;
    color: $white;
    border-top-right-radius: 500px;
    border-bottom-right-radius: 500px;
    overflow: hidden;
  }

  &__header:after {
    content: '';
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: $primaryWithOpacity;
  }

  h3 {
    z-index: 100;
    font-weight: normal;
    margin-left: 20px;

    @media (max-width: 400px) {
      font-size: 16px;
    }
  }

  &__container {
    padding: 20px;
    margin-top: $marginTop;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__picture {
    width: 100%;
    height: 150px;
    margin: 20px auto;
    display: flex;
    border-radius: 5px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__description {
    font-size: 14px;
    line-height: 20px;
  }

  &__link {
    margin-top: $marginTop;
  }

  &__link a:link,
  &__link a:active,
  &__link a:hover,
  &__link a:visited {
    color: $primaryWithoutOpacity;
    text-decoration: none;
  }

  &__link a:hover {
    font-weight: bold;
    font-size: 15px;
  }

  &__button {
    width: 60%;
    height: 25px;
    border: 1px solid $primaryWithOpacity;
    color: $primaryWithoutOpacity;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    position: absolute;
    bottom: 30px;
    text-transform: uppercase;
    text-decoration: none;
  }

  &__button:hover {
    background-color: $primaryWithoutOpacity;
    color: white;
  }
}
