@import '../../css/variables';

#certifications {
  width: 100%;
  background-color: $white;
  border-top: 1px solid rgba($primaryWithoutOpacity, 0.5);
  border-bottom: 1px solid rgba($primaryWithoutOpacity, 0.5);

  &__container {
    width: 100%;
    max-width: 1200px;
    color: rgb(130, 130, 130);
    height: 100%;
    margin: 0 auto;
    display: flex;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

  }

  &__header {
    width: 100%;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;

    h2 {
      z-index: 100;
      font-weight: normal;
      letter-spacing: 10px;
      color: $primaryWithoutOpacity;
      text-transform: uppercase;
      font-size: 30px;
      position: relative;
      top: 20px;

      @media (max-width: 800px) {
        font-size: 24px;
      }

      @media (max-width: 600px) {
        font-size: 20px;
        letter-spacing: 5px;
      }
    }

    h2:after {
      content: "";
      display: flex;
      width: 200%;
      height: 1px;
      margin: 10px auto 0;
      position: absolute;
      left: -50%;

      @media (max-width: 450px) {
        width: 150%;
        left: -25%;
      }

      background-image: linear-gradient(
      to right,
      $lightGrey1,
      $primaryWithOpacity 40%,
      $primaryWithOpacity 60%,
      $lightGrey1
    );
    }
  }

  &__list-wrapper {
    margin-top: 120px;
    margin-bottom: 100px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

}