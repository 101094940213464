$white: white;
$whiteWithopacity: rgba(255, 255, 255, 0.9);
$black: black;
$primaryWithOpacity: #1d334bd9;
$primaryWithoutOpacity: #1d334b;
$marked: #1d334b58;
$lightGrey1: #f0f0f0;
$text-shadow: #abc8e8;
$notValid: rgba(255, 0, 0, 0.6);


