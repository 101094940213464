@import '../../css//variables';

@keyframes MOVE-BG {
  from {
    top: 0;
  }
  to {
    top: -312px;
  }
}

#header {
  position: fixed;
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  z-index: 1000;

  &__background {
    width: 100%;
    height: 700%;
    color: $white;
    background-color: $white;
    position: absolute;
    top: 0;
    left: 0;
    animation-name: MOVE-BG;
    animation-duration: 30s;
    animation-timing-function: linear;
  }

  &__background:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $primaryWithOpacity;
  }

  &__container {
    position: absolute;
    top: 0;
    color: $white;
    display: block;
    height: 100%;
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;

    a {
      text-decoration: none;
    }

    @media (max-width: 500px) {
      padding: 0;
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: lighter;
    font-size: 1.5rem;
    letter-spacing: 20px;
    color: $white;
    text-shadow: 0px 0px 10px $text-shadow;

    @media (max-width: 1250px) {
      margin: 0 auto;
    }

    @media (max-width: 500px) {
      font-size: 5vw;
      letter-spacing: 4vw;
    }
  }

  &__nav {
    width: 50%;
    height: 100%;

    @media (max-width: 1000px) {
      display: none;
    }

    & ul {
      display: flex;
      width: 100%;
      height: 100%;
    }

    & li {
      list-style-type: none;
      margin: 0 auto;
      height: 100%;
      width: 100%;
    }
    & a {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: $whiteWithopacity;
    }

    & a:hover {
      color: $white;
      background-image: linear-gradient(
        to bottom,
        $white,
        $white 2px,
        rgba($white, 0.5) 2px,
        rgba($white, 0) 15px
      );
      text-shadow: 0px 0px 10px $text-shadow;
    }
  }
}

#header__mobile-nav {
  height: 100%;
  width: 55px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 14px 0;
  cursor: pointer;
  position: absolute;
  right: 0;

  @media (min-width: 1001px) {
    display: none;
  }

  @media (max-width: 500px) {
    padding: 16px 0;
    width: 50px;
  }

  @media (max-width: 300px) {
    padding: 18px 0;
    width: 50px;
  }
}

#header__hamburger {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  div {
    display: block;
    width: 50%;
    height: 3px;
    background-color: $whiteWithopacity;
    border-radius: 5px;

    @media (max-width: 550px) {
      height: 2px;
      width: 40%;
    }
  }
}
